import React from "react";

export default function EmojiCard({ emoji, children }) {
  return (
    <div className="rounded border-0 p-8 text-center border-accent">
      <p className="text-5xl">
        <span role="img" aria-label="emoji">
          {emoji}
        </span>
      </p>
      <p className="text-2xl">{children}</p>
    </div>
  );
}
