import React from "react";
import { Layout } from "../components/Layout";
import EmojiCard from "../components/EmojiCard";
import Img from "gatsby-image";
import { graphql, Link } from "gatsby";
import { A } from "../components/A";
import { MyEmail } from "../components/MyEmail";

export default function Home({ data }) {
  return (
    <Layout pageTitle={data.site.siteMetadata.title}>
      <div className="px-0 md:px-20 lg:px-40">
        <div className="flex flex-col md:flex-row justify-around py-8 lg:py-20">
          <EmojiCard emoji="⚡">Energetic</EmojiCard>
          <EmojiCard emoji="💻">Techie</EmojiCard>
          <EmojiCard emoji="🙋‍♂️">Curious</EmojiCard>
        </div>
        <div className="flex flex-col lg:flex-row justify-between">
          <div className="flex-1 border-accent border-l-4 pl-4 mb-8 lg:mb-0 prose">
            <h1>Lil' about me</h1>
            <p>
              Whoosh; I'm 14 years old and find out{" "}
              <A href="https://www.youtube.com/watch?v=5s5EvhHy7eQ">
                the Wii controller can be connected through bluetooth
              </A>
              .
            </p>
            <p className="font-semibold">
              <span role="img" aria-label="brain">
                🧠
              </span>{" "}
              BOOM mind is blown!
            </p>
            <p>
              Fastforward to my 18th: I find out programming is a{" "}
              <A href="https://youtu.be/I14b-C67EXY?t=83">wanted profession</A>.
            </p>
            <p className="font-semibold">
              <span role="img" aria-label="mind blown">
                🤯
              </span>{" "}
              CAN'T BELIEVE IT!
            </p>
            <p>
              I'm 23 years old and meet a founder at a bar in Berlin. I can come
              and set up <A href="https://www.legalos.io/product/">their</A>{" "}
              tech.
            </p>
            <p className="font-semibold">
              <span role="img" aria-label="heart">
                💖
              </span>{" "}
              LOVE IT
            </p>
            <p>I dabble in Product Management and being a one-man-army, but software engineer is where my heart is at.</p>
            <p><span role="img" aria-label="rocket">🚀</span> Web3 is where I'm headed next! I'm looking for a gig!</p>
            <p>
              Feel free to contact me at <MyEmail />
            </p>
            <p>
              In the meanwhile check out some of my{" "}
              <Link to="/work" className="underline">
                work
              </Link>{" "}
              or read some of my{" "}
              <Link to="/thoughts" className="underline">
                thoughts
              </Link>
              .
            </p>
          </div>
          <Img
            className="self-center"
            fixed={data.file.childImageSharp.fixed}
          />
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query PortraitQuery {
    site {
      siteMetadata {
        title
      }
    }
    file(relativePath: { eq: "img/portrait.jpg" }) {
      childImageSharp {
        fixed(width: 240) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
